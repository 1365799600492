// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-category-cat-id-tsx": () => import("./../../../src/pages/category/{Category.catId}.tsx" /* webpackChunkName: "component---src-pages-category-category-cat-id-tsx" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-thead-thead-thead-id-tsx": () => import("./../../../src/pages/thead/{Thead.theadId}.tsx" /* webpackChunkName: "component---src-pages-thead-thead-thead-id-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

